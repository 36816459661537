/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(search, replacement) {
      return this.replace(new RegExp(search, 'g'), replacement);
    };
  }